import { createReducer } from "@reduxjs/toolkit";
import { EarSide } from "../../../Common/Types/EarSide";
import { SessionStorage } from "../../../Infrastructure/Services/SessionStorage";
import { UnitType, GraphViewType, ViewTabType } from "../queries/useGetPerformanceGraphQuery";
import * as actions from "./actions";

interface PerformanceGraphState {
    viewTab: ViewTabType;
    graphViewTuning: GraphViewType;
    graphViewMpo: GraphViewType;
    highlightedLegendItem: string | null;
    highlightedCurves: {
        [key in EarSide]: string[];
    };
    [GraphViewType.outputVsFrequency]: {
        unitType: UnitType;
    };
    [GraphViewType.insertionGainVsFrequency]: {
        unitType: UnitType;
    };
}

const initialState: PerformanceGraphState = {
    viewTab: SessionStorage.getItem("view_tab", "Tuning"),
    graphViewTuning: SessionStorage.getItem(
        "graph_view_tuning",
        GraphViewType.insertionGainVsFrequency
    ),
    graphViewMpo: SessionStorage.getItem("graph_view_mpo", GraphViewType.outputVsFrequency),
    highlightedLegendItem: null,
    highlightedCurves: {
        left: [],
        right: [],
    },
    [GraphViewType.outputVsFrequency]: {
        unitType: UnitType.dBHL,
    },
    [GraphViewType.insertionGainVsFrequency]: {
        unitType: UnitType.dB,
    },
};

const performanceGraphReducer = createReducer<PerformanceGraphState>(initialState, builder =>
    builder
        .addCase(actions.setViewTab, (state, { payload }) => {
            state.viewTab = payload;
            SessionStorage.setItem("view_tab", payload);
        })
        .addCase(actions.setGraphView, (state, { payload }) => {
            switch (payload.tab) {
                case "Tuning":
                    SessionStorage.setItem("graph_view_tuning", payload);
                    state.graphViewTuning = payload.graphView;
                    console.log(`Set tuning configs ${JSON.stringify(state)}`);
                    break;
                case "Mpo":
                    SessionStorage.setItem("graph_view_mpo", payload);
                    state.graphViewMpo = payload.graphView;
                    break;
            }
        })
        .addCase(actions.setUnitTypeForGraphView, (state, { payload }) => {
            state[payload.graphView].unitType = payload.unitType;
        })
        .addCase(actions.highlightLegendItem, (state, action) => {
            state.highlightedLegendItem = action.payload;
        })
        .addCase(actions.highlightCurves, (state, action) => {
            state.highlightedCurves[action.payload.earSide] = action.payload.curves;
        })
);

export default performanceGraphReducer;
